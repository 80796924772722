
/*Navbar css*/
@media (min-width: 992px) {
    .navbar {
      padding: 0 5.2vw;
      height: 9vh;
    }
}



.logo-navbar{
  object-fit: contain;
  max-width: 100px;
  }

  .custom-active {
    color: inherit; /* Mantém a cor padrão */
  }
  
  .custom-active.active,
  .custom-active[aria-current="page"] {
    color: var(--cor-primaria) !important; /* Cor azul para o link ativo */
  }

  .dropdown-menu {
    background-color: #fff; /* Fundo branco */
    border-radius: 0.25rem; /* Bordas arredondadas */
    border: none;
  }


  .dropdown-item:hover  {
    background-color: #f1f1f1; /* Cor de fundo ao passar o mouse */
    color: --cor-primaria;
  }
  
  @media (max-width: 992px) {
    .navbar .dropdown{
      display:none;
    }
  }
