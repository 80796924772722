@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');


:root {
  --cor-primaria: #2194F3;
  --cor-text-primaria: #333;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito Sans', sans-serif;
}

@media (min-width: 2000px) {
  body {
    zoom: 1.2; /* Aplica um zoom de 20% */
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.config-padding{
  padding:0 5.2vw
}

.text-blue{
  color: var(--cor-primaria);
}

.text-secondary-bg {
  color: var(--bs-secondary-bg);
}

.page{
  margin:3% 0 0 0;
}
@media(max-width: 992px){
  .page{
    padding:10% 0;
  }
}