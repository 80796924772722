.App {
  text-align: center;
  background-color: --bs-body-color;
max-width: 100vw !important;
}
@media (min-width: 992px) {
  .App {
    margin-top: 9vh;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media(min-width: 1024px) {
.zoom:hover {
  transition: height 0.5s ease-out;
  transform: scale(1.2); 
}
.zoom-05:hover {
  transition: height 0.5s ease-out;
  transform: scale(1.1);
}
}

