footer .logo-navbar{
    height: 70px;
    width: 130px;
    object-fit: contain;
  }
    footer {
        padding: 0 5.2vw;
        margin-right:0 !important;
        margin-left:0 !important;
    }
